import styled from 'styled-components'

export const TradingContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(15, 1fr);
  grid-template-rows: auto auto;
  grid-auto-flow: row dense;
  grid-column-gap: 16px;
  grid-row-gap: 8px;
  margin-top: 42px;
`
