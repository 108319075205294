import styled, { keyframes } from 'styled-components/macro'

const rotate = keyframes`
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
`

const StyledSVG = styled.svg<{ size: string; stroke?: string }>`
  animation: 2s ${rotate} ease-out infinite;
  height: ${({ size }) => size};
  width: ${({ size }) => size};
  path {
    stroke: ${({ stroke, theme }) => stroke ?? theme.colors.primary};
  }
`

export const GlobalLoaderContainer = styled.div`
  position: fixed;
  bottom: 20px;
  left: 20px;
  z-index: 102;
`

const Loader = ({ size = '16px', stroke, ...rest }: { size?: string; stroke?: string; [k: string]: any }) => (
  <StyledSVG viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg" size={size} stroke={stroke} {...rest}>
    <path
      d="M12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22C17.5228 22 22 17.5228 22 12C22 9.27455 20.9097 6.80375 19.1414 5"
      strokeWidth="1"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </StyledSVG>
)

export default Loader
