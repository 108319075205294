import styled from 'styled-components'

export const SwitchSpan = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.tertiary};
  border-radius: 16px;
  transition: 0.4s;

  &:before {
    position: absolute;
    content: '';
    height: 18px;
    width: 18px;
    border-radius: 18px;
    left: 2px;
    bottom: 2px;
    background-color: ${({ theme }) => theme.colors.white};
    transition: 0.4s;
  }
`

export const SwitchInput = styled.input`
  width: 0;
  height: 0;
`

export const SwitchLabel = styled.label`
  position: relative;
  display: inline-flex;
  width: 44px;
  height: 22px;

  input:checked + span:before {
    -webkit-transform: translateX(22px);
    -ms-transform: translateX(22px);
    transform: translateX(22px);
  }

  input:checked + span {
    background-color: ${({ theme }) => theme.colors.primary};
  }
`
