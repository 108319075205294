import Loader from 'components/Loader'
import { ChangeEvent, CSSProperties, forwardRef, useRef } from 'react'
import { Search as SearchIcon } from 'react-feather'

import { IconContainer, SearchContainer, SearchField } from './styles'

const Search = forwardRef<
  HTMLElement | undefined,
  {
    value: string
    placeholder: string
    onSearchChange: (text: string) => void
    containerStyles?: CSSProperties
    iconStyles?: CSSProperties
    isLoading?: boolean
  }
>(
  (
    { value = '', placeholder = '', onSearchChange = () => null, containerStyles, iconStyles, isLoading = false },
    ref
  ) => {
    const searchInputRef = useRef<any>()

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
      onSearchChange(event.target.value)
    }
    const handleFocusInput = () => searchInputRef.current?.focus()

    return (
      <SearchContainer ref={ref as any} style={containerStyles} onClick={handleFocusInput}>
        <SearchField ref={searchInputRef} type="text" value={value} placeholder={placeholder} onChange={handleChange} />
        <IconContainer style={iconStyles}>
          {isLoading ? <Loader size="25px" style={{ marginRight: '8px' }} /> : null}
          <SearchIcon color="white" />
        </IconContainer>
      </SearchContainer>
    )
  }
)

export default Search
