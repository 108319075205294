import axios from 'axios'
import { CURRENCY_PRIORITIES } from 'constants/common'

export const fetchAllNews = async (offset: number) => {
  const { data }: { data: Promise<any> } = await axios.get(`${process.env.REACT_APP_LENTA_NEWS_URL}/all`, {
    params: { offset },
  })

  return data
}

export const fetchSearchNews = async (searchText: string, offset: number) => {
  const { data }: { data: Promise<any> } = await axios.get(
    `${process.env.REACT_APP_LENTA_NEWS_URL}/search/${searchText}`,
    {
      params: { offset },
    }
  )

  return data
}

export const fetchTagsNews = async (tags: Set<string>, offset: number) => {
  const { data }: { data: Promise<any> } = await axios.get(
    `${process.env.REACT_APP_LENTA_NEWS_URL}/tag/${Array.from(tags).join(',')}`,
    {
      params: { offset },
    }
  )

  return data
}

export const fetchTags = async () => {
  const { data }: { data: any } = await axios.get(`${process.env.REACT_APP_LENTA_NEWS_URL}/tags`)

  return data?.sort?.((a: string, b: string) => (CURRENCY_PRIORITIES[a] > (CURRENCY_PRIORITIES[b] || 0) ? -1 : 1)) ?? []
}
