import Box from 'components/Box'
import { NavLink } from 'react-router-dom'
import styled from 'styled-components'

export const HeaderContainer = styled.div<{ showSearchInHeader?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  height: 112px;
  padding: 36px 40px 0;

  background: ${({ showSearchInHeader }) =>
    showSearchInHeader ? '#0C0232' : 'linear-gradient(180deg, #0d0232 23.65%, rgba(12, 2, 51, 0) 100%)'};

  z-index: 100;

  .active {
    border-bottom: 1px solid ${({ theme }) => theme.colors.primary};
  }
`

export const Link = styled(NavLink)`
  padding: 8px 20px;
  color: ${({ theme }) => theme.colors.white};
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration: none;
  white-space: nowrap;
`

export const SidebarHeader = styled(Box)`
  .active {
    color: ${({ theme }) => theme.colors.primary};
  }
`

export const CloseContainer = styled.div`
  position: absolute;
  left: 16px;
`
