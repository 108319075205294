import styled from 'styled-components'

export default styled.span<{
  color?: string
  fontSize?: string
  fontWeight?: string
  lineHeight?: string
  margin?: string
  padding?: string
}>`
  color: ${({ color, theme }) => color || theme.colors.primary};
  display: inline-flex;
  font-size: ${({ fontSize, theme }) => fontSize || `${theme.fontSizes[4]}px`};
  font-weight: ${({ fontWeight, theme }) => fontWeight || theme.fontWeights.normal};
  line-height: ${({ lineHeight, theme }) => lineHeight || theme.fontSizes[5]}px;
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
`
