import { ChangeEvent, FC, useMemo, useState } from 'react'
import { X, Check } from 'react-feather'
import { useTheme } from 'styled-components'
import { Search as SearchIcon } from 'react-feather'
import { useQuery } from '@tanstack/react-query'

import Box from 'components/Box'
import Button, { ButtonType } from 'components/Button'
import Slider, { labelStyle } from 'components/Slider'
import Switch from 'components/Switch'
import Text from 'components/Text'
import CurrencyIcon from 'components/CurrencyIcon'
import Tag from 'components/Tag'
import Input from 'components/Input'
import { fetchTags } from 'connectors/news'
import { useNewsStore } from 'store/news'

import { CurrenciesList, IconContainer } from './styles'
import { useNotification } from 'hooks/useNotification'

const marks = {
  0: {
    label: 'low',
    style: labelStyle,
  },
  50: {
    label: 'medium',
    style: labelStyle,
  },
  100: {
    label: 'high',
    style: labelStyle,
  },
}

const CreateAlert: FC<{
  onClose?: () => void
  defaultCurrencies: string[]
}> = ({ onClose = () => null, defaultCurrencies = [] }) => {
  const theme = useTheme()

  const { addAlertRule } = useNewsStore()
  const { isSupported, isGranted, requestPermission } = useNotification()
  const [createAlertCurrencies, setCreateAlertCurrencies] = useState<string[]>(defaultCurrencies)
  const [isCurrenciesSelection, setCurrenciesSelection] = useState(false)
  const [emailNotification, setEmailNotification] = useState(true)
  const [pushNotification, setPushNotification] = useState(false)
  const [notificationImportance, setNotificationImportance] = useState<number | number[]>(50)
  const [email, setEmail] = useState('')
  const [search, setSearch] = useState('')

  const { data = [] } = useQuery(['tags'], fetchTags)

  const currenciesList = useMemo(
    () =>
      data
        .filter((currency: string) => {
          const hay = currency.toLowerCase(),
            s = search.toLowerCase()

          let i = 0,
            n = -1,
            l

          for (; (l = s[i++]); ) if (!~(n = hay.indexOf(l, n + 1))) return false
          return true
        })
        .map((currency: string) => ({
          name: currency,
          shortName: currency,
          isActive: createAlertCurrencies.some((c) => c === currency),
        })),
    [data, createAlertCurrencies, search]
  )

  const handleChangeEmailNotification = (event: ChangeEvent<HTMLInputElement>) =>
    setEmailNotification(event.target.checked)
  const handleChangePushNotification = (event: ChangeEvent<HTMLInputElement>) =>
    setPushNotification(event.target.checked)
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)
  const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => setSearch(event.target.value)
  const handleImportanceChange = (event: number | number[]) => setNotificationImportance(event)
  const handleShowCurrenciesSelection = () => setCurrenciesSelection((state) => !state)
  const handleCreateAlert = () => {
    if (pushNotification && isSupported) {
      if (isGranted) {
        addAlertRule({
          currencies: createAlertCurrencies,
          importance: notificationImportance,
        })

        onClose()
      } else {
        requestPermission(
          () => {
            addAlertRule({
              currencies: createAlertCurrencies,
              importance: notificationImportance,
            })

            onClose()
          },
          () => {
            onClose()
          }
        )
      }
    }
  }

  if (isCurrenciesSelection) {
    return (
      <Box flexDirection="column" alignItems="center" height="auto">
        <Box
          flexDirection="row"
          flexWrap="nowrap"
          justifyContent="space-between"
          alignItems="center"
          width="100%"
          margin="0 0 32px 0"
        >
          <Text fontSize="16px" lineHeight="18px" fontWeight="600" color={theme.colors.white} margin="0 8px 0 0">
            Choose the cryptocurrencies you want to get alerts for:
          </Text>
          <X size={24} color={theme.colors.white} onClick={onClose} style={{ cursor: 'pointer' }} />
        </Box>
        <Box width="100%" height="auto">
          <Input value={search} placeholder="Search" onChange={handleSearchChange} />
          <IconContainer>
            <SearchIcon size={16} color="white" />
          </IconContainer>
        </Box>
        <CurrenciesList>
          {currenciesList?.map(
            ({ name, shortName, isActive }: { name: string; shortName: string; isActive: boolean }) => {
              const handleUpdateSelectedCurrencies = () => {
                if (isActive) {
                  setCreateAlertCurrencies(createAlertCurrencies.filter((c: string) => c !== shortName))
                } else {
                  setCreateAlertCurrencies([...createAlertCurrencies, shortName])
                }
              }

              return (
                <Box
                  key={shortName}
                  width="100%"
                  height="auto"
                  flexDirection="row"
                  alignItems="center"
                  gap="16px"
                  margin="0 -24px"
                  padding="10px 24px"
                  onClick={handleUpdateSelectedCurrencies}
                  style={{
                    cursor: 'pointer',
                    backgroundColor: isActive ? theme.colors.tertiaryBackground : 'transparent',
                  }}
                >
                  <CurrencyIcon useGrayFilter currency={shortName} width="32px" height="32px" />
                  <Box width="100%" height="auto" justifyContent="space-between" alignItems="center" gap="8px">
                    <Box flexDirection="column">
                      <Text fontSize="12px" fontWeight="600" color={theme.colors.white}>
                        {shortName}
                      </Text>
                      <Text fontSize="12px" color={theme.colors.tertiary}>
                        {name}
                      </Text>
                    </Box>
                    {isActive ? <Check size={19} color="white" /> : null}
                  </Box>
                </Box>
              )
            }
          )}
        </CurrenciesList>
        <Button
          buttonType={ButtonType.primary}
          color={theme.colors.white}
          margin="24px 0 0"
          onClick={handleShowCurrenciesSelection}
        >
          Done
        </Button>
      </Box>
    )
  }

  return (
    <Box flexDirection="column" height="auto">
      <Box
        flexDirection="row"
        flexWrap="nowrap"
        justifyContent="space-between"
        alignItems="center"
        width="100%"
        margin="0 0 32px 0"
      >
        <Text fontSize="24px" lineHeight="29px" fontWeight="600" color={theme.colors.white} margin="0 8px 0 0">
          Create alert
        </Text>
        <X size={24} color={theme.colors.white} onClick={onClose} style={{ cursor: 'pointer' }} />
      </Box>
      <Text fontSize="14px" lineHeight="16px" fontWeight="600" color={theme.colors.white} margin="0 0 16px 0">
        Select cryptocurrencies
      </Text>
      <Box flexDirection="row" flexWrap="wrap" height="auto" margin="0 0 8px">
        {createAlertCurrencies?.map((coin: string) => {
          return (
            <Tag
              margin="0 8px 8px 0"
              key={coin}
              title={coin}
              LeftIcon={CurrencyIcon}
              textColor={theme.colors.white}
              backgroundColor={theme.colors.quaternaryBackgroundAlt}
            />
          )
        })}
        <Text
          fontSize="12px"
          lineHeight="14px"
          margin="4px 0 0 0"
          color={theme.colors.white}
          style={{ textDecoration: 'underline', cursor: 'pointer' }}
          onClick={handleShowCurrenciesSelection}
        >
          Show all
        </Text>
      </Box>
      <Text fontSize="14px" lineHeight="16px" fontWeight="600" color={theme.colors.white} margin="0 0 16px 0">
        Choose importance of the alerts
      </Text>
      <Box width="92%" height="auto" margin="0 0 24px 8px">
        <Slider step={50} defaultValue={50} marks={marks} onChange={handleImportanceChange} />
      </Box>
      <Text fontSize="14px" lineHeight="16px" fontWeight="600" color={theme.colors.white} margin="24px 0 16px 0">
        Alerts
      </Text>
      <Box flexDirection="row" flexWrap="wrap" gap="16px" width="100%" height="auto">
        <Box flexDirection="row" alignItems="center" flexWrap="nowrap">
          <Switch checked={emailNotification} onChange={handleChangeEmailNotification} />
          <Text fontSize="12px" lineHeight="14px" margin="0 0 0 8px" color={theme.colors.white}>
            Email
          </Text>
        </Box>
        <Box flexDirection="row" alignItems="center" flexWrap="nowrap">
          <Switch checked={pushNotification} onChange={handleChangePushNotification} />
          <Text fontSize="12px" lineHeight="14px" margin="0 0 0 8px" color={theme.colors.white}>
            Push up notifications
          </Text>
        </Box>
      </Box>
      <Box width="100%" height="auto" margin="16px 0 0">
        <Input
          value={email}
          disabled={!emailNotification}
          placeholder="Enter your email"
          onChange={handleInputChange}
        />
      </Box>
      <Button buttonType={ButtonType.primary} color={theme.colors.white} onClick={handleCreateAlert} margin="24px 0 0">
        Create alert
      </Button>
    </Box>
  )
}

export default CreateAlert
