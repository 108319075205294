import { createContext, useContext, useEffect, useState } from 'react'
import Push from 'push.js'

import { NOTIFICATION_TIMEOUT } from 'constants/common'

type ShowNotificationParams = {
  title: string
  body: string
}

export type NotificationParams = {
  isSupported: boolean
  isGranted: boolean
  requestPermission: (onGranted: () => void, onDenied: () => void) => void
  showNotification: ({ title, body }: ShowNotificationParams) => void
}

const defaultNotificationInfo: NotificationParams = {
  isSupported: false,
  isGranted: false,
  requestPermission: () => null,
  showNotification: () => null,
}

export const NotificationContext = createContext(defaultNotificationInfo)

export const NotificationProvider = ({ children }: { children: JSX.Element | JSX.Element[] }) => {
  const [isSupported, setSupported] = useState<boolean>(false)
  const [isGranted, setGranted] = useState<boolean>(false)

  useEffect(() => {
    if (Push.Permission.get()) {
      setSupported(true)

      if (Push.Permission.has()) {
        setGranted(true)
      }
    }
  }, [])

  const requestPermission = (onGranted?: () => void, onDenied?: () => void) => {
    if (isSupported) {
      Push.Permission.request(
        () => {
          setGranted(true)
          onGranted?.()
        },
        () => {
          setGranted(false)
          onDenied?.()
        }
      )
    }
  }

  const createNotification = async ({ title, body }: ShowNotificationParams) =>
    await Push.create(title, {
      body,
      icon: './favicon.ico',
      link: 'news',
      timeout: NOTIFICATION_TIMEOUT,
    })

  const showNotification = async ({ title, body }: ShowNotificationParams) => {
    if (isSupported && isGranted) {
      return await createNotification({ title, body })
    }

    return null
  }

  return (
    <NotificationContext.Provider
      value={{
        isSupported,
        isGranted,
        requestPermission,
        showNotification,
      }}
    >
      {children}
    </NotificationContext.Provider>
  )
}

export const useNotification = (): NotificationParams => useContext(NotificationContext)
