import styled, { css } from 'styled-components'
import theme from 'theme'

export enum ButtonType {
  primary = 'PRIMARY',
  secondary = 'SECONDARY',
  default = 'DEFAULT',
}

const themedButton = {
  [ButtonType.primary]: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.primary,
    color: theme.colors.primary,
    hoverBackgroundColor: theme.colors.primary,
    hoverColor: theme.colors.primaryBackground,
  },
  [ButtonType.secondary]: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.secondary,
    color: theme.colors.secondary,
    hoverBackgroundColor: theme.colors.secondary,
    hoverColor: theme.colors.primaryBackground,
  },
  [ButtonType.default]: {
    backgroundColor: 'transparent',
    borderColor: theme.colors.tertiary,
    color: theme.colors.tertiary,
    hoverBackgroundColor: theme.colors.tertiary,
    hoverColor: theme.colors.primaryBackground,
  },
}

export default styled.button<{
  backgroundColor?: string
  borderColor?: string
  buttonType: ButtonType
  color?: string
  height?: string
  hoverBackgroundColor?: string
  hoverColor?: string
  margin?: string
  padding?: string
  width?: string
}>`
  border-radius: 100px;
  border-style: solid;
  border-width: 1px;
  cursor: pointer;
  white-space: nowrap;
  height: ${({ height }) => height || '40px'};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || '8px 32px'};
  width: ${({ width }) => width || 'auto'};

  ${({ buttonType, color, hoverColor, backgroundColor, hoverBackgroundColor, borderColor }) => {
    switch (buttonType) {
      case ButtonType.primary:
        return css`
          background-color: ${themedButton.PRIMARY.backgroundColor};
          border-color: ${themedButton.PRIMARY.borderColor};
          color: ${color || themedButton.PRIMARY.color};

          &:hover {
            background-color: ${themedButton.PRIMARY.hoverBackgroundColor};
            color: ${themedButton.PRIMARY.hoverColor};
          }
        `
      case ButtonType.secondary:
        return css`
          background-color: ${themedButton.SECONDARY.backgroundColor};
          border-color: ${themedButton.SECONDARY.borderColor};
          color: ${color || themedButton.SECONDARY.color};

          &:hover {
            background-color: ${themedButton.SECONDARY.hoverBackgroundColor};
            color: ${themedButton.SECONDARY.hoverColor};
          }
        `
      case ButtonType.default:
      default:
        return css`
          background-color: ${backgroundColor || themedButton.DEFAULT.backgroundColor};
          border-color: ${borderColor || themedButton.DEFAULT.borderColor};
          color: ${color || themedButton.DEFAULT.color};

          &:hover {
            background-color: ${hoverBackgroundColor || themedButton.DEFAULT.hoverBackgroundColor};
            color: ${hoverColor || themedButton.DEFAULT.hoverColor};
          }
        `
    }
  }}
`
