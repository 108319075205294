import { FC, useEffect } from 'react'
import { useLocation, useNavigationType } from 'react-router-dom'

const ScrollToTop: FC<{
  children: JSX.Element | JSX.Element[]
}> = ({ children }) => {
  const location = useLocation()
  const navigationType = useNavigationType()

  useEffect(() => {
    if (navigationType !== 'POP') {
      window.scrollTo(0, 0)
    }
  }, [location, navigationType])

  return <>{children}</>
}

export default ScrollToTop
