import Box from 'components/Box'
// import Button, { ButtonType } from 'components/Button'
import { CloseContainer, HeaderContainer, Link, SidebarHeader } from './styles'
import { useNewsStore } from 'store/news'
import Search from 'components/Search'
import { useMediaQuery } from 'react-responsive'
import { Menu, X } from 'react-feather'
import { DefaultTheme, useTheme } from 'styled-components'
import Sidebar from 'components/Sidebar'

import { ReactComponent as Logo } from '../../assets/svg/hs_logo.svg'
import { ReactComponent as LogoMobile } from '../../assets/svg/hs_logo_mobile.svg'
import { IS_MOBILE_MEDIA_QUERY } from 'constants/common'

const Header = () => {
  const theme: DefaultTheme = useTheme()
  const { search, updateSearch, showSearchInHeader, showHeaderSidebar, updateShowSidebar } = useNewsStore()

  const isMobile = useMediaQuery(IS_MOBILE_MEDIA_QUERY)

  const handleSearchChange = (text: string) => updateSearch(text)
  const handleSidebarChange = () => updateShowSidebar(!showHeaderSidebar)

  return (
    <HeaderContainer showSearchInHeader={showSearchInHeader}>
      {isMobile ? (
        <LogoMobile style={{ marginTop: '6px', minWidth: '32px' }} fill="white" title="logo" />
      ) : (
        <Logo style={{ minWidth: '174px' }} fill="white" title="logo" />
      )}
      {showSearchInHeader ? (
        <Search
          placeholder="Crypto news search"
          value={search}
          onSearchChange={handleSearchChange}
          containerStyles={{
            flex: 1,
            margin: '0 0 0 24px',
            width: 'auto',
            maxWidth: 'none',
            padding: '10px 24px',
          }}
          iconStyles={{
            top: '8px',
          }}
        />
      ) : null}
      {isMobile ? (
        <>
          <Menu
            color={theme.colors.white}
            size={32}
            style={{ minWidth: '32px', marginLeft: '32px', marginTop: '6px' }}
            onClick={handleSidebarChange}
          />
          <Sidebar isOpen={showHeaderSidebar} onClose={handleSidebarChange}>
            <SidebarHeader
              margin="48px 24px 24px"
              width="100%"
              alignItems="center"
              flexDirection="column"
              backgroundColor="transparent"
            >
              <CloseContainer>
                <X color={theme.colors.white} onClick={handleSidebarChange} />
              </CloseContainer>
              {/* <Link to={`/features`}>Features</Link> */}
              {/* <Link to={`/about`}>About us</Link> */}
              <Link to={`/news`}>News</Link>
              {/* TODO add back*/}
              {/* <Button margin="16px 0 0" buttonType={ButtonType.primary}>
                Launch app
              </Button> */}
            </SidebarHeader>
          </Sidebar>
        </>
      ) : (
        <>
          <Box
            justifyContent="flex-end"
            margin="0 24px"
            style={{ flex: showSearchInHeader ? 0 : 1 }}
            backgroundColor="transparent"
          >
            {/* <Link to={`/features`}>Features</Link> }
            { <Link to={`/about`}>About us</Link>
            <Link to={`/faq`}>FQA</Link> */}
            <Link to={`/news`}>News</Link>
          </Box>
          {/* TODO add back*/}
          {/* <Button buttonType={ButtonType.primary}>Launch app</Button> */}
        </>
      )}
    </HeaderContainer>
  )
}

export default Header
