import ReactDOM from 'react-dom'

import Sidebar, { TSidebar } from './Sidebar'

let sidebarRoot = document.getElementById('sidebar-root') as HTMLElement
if (sidebarRoot === null) {
  sidebarRoot = document.createElement('div')
  sidebarRoot.setAttribute('id', 'sidebar-root')
  document.body.appendChild(sidebarRoot)
}

const SidebarPortal = (props: TSidebar) => ReactDOM.createPortal(<Sidebar {...props} />, sidebarRoot)

export default SidebarPortal
