import styled, { css } from 'styled-components'

export const NewsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 30px 30px 0 0;

  > div:first-of-type {
    border-radius: 30px 30px 0 0;
  }

  > div:not(:last-of-type) {
    border-bottom: 1px solid ${({ theme }) => theme.colors.tertiary};
  }
`

export const InfoContainer = styled.div<{ isActive?: boolean }>`
  display: flex;
  flex-direction: column;
  padding: 32px;
  background-color: ${({ isActive, theme }) =>
    isActive ? theme.colors.tertiaryBackground : theme.colors.secondaryBackground};
`

export const TagsContainer = styled.div<{ showSearchInHeader?: boolean; isDetailedViewActive?: boolean }>`
  display: flex;
  position: sticky;
  top: 112px;
  flex-direction: row;
  width: 100%;

  align-items: center;
  justify-content: center;
  flex-wrap: wrap;

  @media (min-width: 1160) {
    margin: 32px 0;
  }

  z-index: 100;

  ${({ showSearchInHeader, isDetailedViewActive, theme }) =>
    showSearchInHeader
      ? css`
          width: ${isDetailedViewActive ? '100%' : '100vw'};
          background-color: ${theme.colors.primaryBackground};
          border-top: 1px solid ${theme.colors.quaternaryBackground};
          border-bottom: 1px solid ${theme.colors.quaternaryBackground};
        `
      : ''}
`

export const LinkContainer = styled.a`
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: ${({ theme }) => theme.colors.tertiary};
  cursor: pointer;
  margin-left: 16px;

  > span {
    display: inline-block;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    max-width: 190px;
    width: 100%;
  }
`
