import 'styled-components'

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: {
      white: string
      black: string
      primary: string
      secondary: string
      tertiary: string
      primaryBackground: string
      secondaryBackground: string
      tertiaryBackground: string
      quaternaryBackground: string
      quaternaryBackgroundAlt: string
    }
    fontWeights: {
      light: string
      normal: string
      medium: string
      semiBold: string
      bold: string
    }
    fontSizes: Array<number>
  }
}

const theme = {
  colors: {
    white: '#FFFFFF',
    black: '#000000',
    primary: '#FF536D',
    secondary: '#53FFAC',
    tertiary: '#8A859C',
    primaryBackground: '#0C0232',
    secondaryBackground: '#241B46',
    tertiaryBackground: '#3C345A',
    quaternaryBackground: '#544D6F',
    quaternaryBackgroundAlt: '#56488C',
  },
  fontWeights: {
    light: '300',
    normal: '400',
    medium: '500',
    semiBold: '600',
    bold: '700',
  },
  fontSizes: [8, 10, 12, 14, 16, 18, 20, 22, 26, 24, 28, 30, 34, 38, 42, 46],
}

export default theme
