import { FC } from 'react'

import { TradingContainer } from './styles'
import SmallInfo from './Widgets/SmallInfo'
import Info from './Widgets/Info'
import Graf from './Widgets/Graf'
import Pair from './Widgets/Pair'

const mockedTradeUp =
  'https://gist.githubusercontent.com/MaxDrozdov/6766c4476476522e697aa11b8f0edea7/raw/5e2c5b495a1ba0e82451946c48fc2bde4063b638/trade_eth.svg'
const mockedTradeDown =
  'https://gist.githubusercontent.com/MaxDrozdov/5868f2e613e1bbb067f35c2289a55a9a/raw/129b5d07a8d82946c6ab86ff5e7a01fe79a79f65/trade_usdc.svg'

//TODO connect to api
const Trading: FC<{
  createAlert: (currencies: string[]) => void
}> = ({ createAlert = () => null }) => {
  return (
    <TradingContainer>
      <Info
        name="Solana"
        shortName="SOL"
        percentagePoints={0.1488399}
        positiveTrend={false}
        rate={473.22}
        marketCup={123333213}
        oneDayVolume={5324244}
        currencyShortNameA="ETH"
        currencyShortNameB="SOL"
        currencyRateA={0.002313366}
        currencyRateB={0.07234579}
        toggleCreateAlert={() => createAlert(['SOL', 'ETH'])}
      />
      <Graf url={mockedTradeUp} range="7 Days" positiveTrend={true} />
      <Graf url={mockedTradeDown} range="1 Day" positiveTrend={false} />
      <Pair currencyShortNameA="ETH" currencyShortNameB="USDC" percentage={55.6} positiveTrend={true} rate={0.543} />
      <SmallInfo
        name="Ether"
        shortName="ETH"
        percentagePoints={3.2131231}
        positiveTrend={true}
        rate={10.22}
        toggleCreateAlert={() => createAlert(['SOL', 'ETH'])}
      />
      <Pair currencyShortNameA="ETH" currencyShortNameB="SOL" percentage={55.6} positiveTrend={true} rate={0.543} />
      <Graf url={mockedTradeUp} range="1 Day" positiveTrend={true} />
      <Pair currencyShortNameA="SOL" currencyShortNameB="ETH" percentage={55.6} positiveTrend={false} rate={0.543} />
    </TradingContainer>
  )
}

export default Trading
