import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  grid-column: span 15;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 12px;
  min-height: 60px;
  padding: 24px;
`
