import React, { FC } from 'react'
import RcSlider from 'rc-slider'
import { useTheme } from 'styled-components'
import 'rc-slider/assets/index.css'

import theme from 'theme/index'

export const labelStyle: React.CSSProperties = {
  marginTop: '8px',
  fontSize: '12px',
  lineHeight: '14px',
  fontWeight: '400',
  color: theme.colors.tertiary,
}

const Slider: FC<{
  step: number
  defaultValue: number
  marks: { [key in number]?: { label: string; style: React.CSSProperties } }
  onChange?: (event: number | number[]) => void
}> = ({ step, defaultValue, marks, onChange = () => null }) => {
  const theme = useTheme()

  const trackStyle: React.CSSProperties = {
    height: '4px',
    borderRadius: '100px',
    backgroundColor: theme.colors.primary,
  }

  const railStyle: React.CSSProperties = {
    height: '4px',
    borderRadius: '100px',
    backgroundColor: theme.colors.quaternaryBackground,
  }

  const handleStyle: React.CSSProperties = {
    width: '22px',
    height: '22px',
    backgroundColor: theme.colors.white,
    opacity: 1,
    border: 'none',
    boxShadow: 'none',
    marginTop: '-10px',
  }

  const dotStyle: React.CSSProperties = {
    display: 'none',
  }

  return (
    <RcSlider
      step={step}
      marks={marks}
      defaultValue={defaultValue}
      trackStyle={trackStyle}
      railStyle={railStyle}
      handleStyle={handleStyle}
      dotStyle={dotStyle}
      onChange={onChange}
    />
  )
}

export default Slider
