import styled, { css } from 'styled-components'

export const SidebarOverlay = styled.div<{
  isOpen: boolean
}>`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  flex: 1;
  z-index: 101;
  background-color: rgba(5, 1, 20, 0.5);

  ${({ isOpen }) =>
    isOpen
      ? css`
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 1s;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 500ms, opacity 500ms;
        `};
`

export const SidebarContainer = styled.div<{
  isLeftSide?: boolean
  isOpen: boolean
}>`
  display: flex;
  position: fixed;
  top: 0;
  bottom: 0;
  z-index: 102;
  max-width: 300px;
  width: 100%;
  height: 100%;
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};

  ${({ isLeftSide }) =>
    isLeftSide
      ? css`
          left: 0;
        `
      : css`
          right: 0;
        `};

  ${({ isOpen }) =>
    isOpen
      ? css`
          visibility: visible;
          transform: translateX(0);
          transition: visibility 0s linear 0s, transform 1s;
        `
      : css`
          visibility: hidden;
          transform: ${({ isLeftSide }: any) => (isLeftSide ? 'translateX(-100%)' : 'translateX(100%)')};
          transition: visibility 0s linear 500ms, transform 500ms;
        `};
`
