import { Clock, X, Link } from 'react-feather'
import { debounce } from 'lodash'
import { DefaultTheme, useTheme } from 'styled-components'
import { ElementType, Fragment, useCallback, useEffect, useLayoutEffect, useMemo, useRef, useState } from 'react'
import { TwitterShareButton, TelegramShareButton, FacebookShareButton } from 'react-share'
import { useInfiniteQuery } from '@tanstack/react-query'
import { useInView } from 'react-intersection-observer'
import { useInViewport } from 'react-in-viewport'
import { useMediaQuery } from 'react-responsive'
import { toast } from 'react-toastify'

import { fetchAllNews, fetchSearchNews, fetchTagsNews } from 'connectors/news'
import { formatDistanceDate } from 'utils/formatDate'
import { ReactComponent as Facebook } from 'assets/svg/facebook.svg'
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg'
import { ReactComponent as Telegram } from 'assets/svg/telegram.svg'
import { ReactComponent as Twitter } from 'assets/svg/twitter.svg'
import {
  REFRESH_TIME,
  NEWS_COUNT,
  IS_MOBILE_MEDIA_QUERY,
  ROOT_MARGIN_IN_VIEW,
  THRESHOLD_IN_VIEW,
  ROOT_MARGIN_VIEWPORT,
} from 'constants/common'
import { convertHtmlToText, hostnameUrl, removeFeedInUrl } from 'utils/common'
import { useNewsStore } from 'store/news'
import Box from 'components/Box'
import Button, { ButtonType } from 'components/Button'
import DetailedView from 'components/DetailedView'
import CurrencyIcon from 'components/CurrencyIcon'
import Search from 'components/Search'
import Tag from 'components/Tag'
import Text from 'components/Text'
import Trading from 'components/Trading'
import CreateAlert from 'components/CreateAlert'
import Modal from 'components/Modal'
import { useNotification } from 'hooks/useNotification'

import { InfoContainer, LinkContainer, NewsContainer, TagsContainer } from './styles'
import { useCommonStore } from 'store/common'

const News = () => {
  const theme: DefaultTheme = useTheme()
  const searchRef = useRef()
  const { toggleComingSoonModal } = useCommonStore()
  const { search, alertStamp, alertRules, updateSearch, updateShowSearch, updateAlertStamp } = useNewsStore()
  const { inViewport: searchInViewport } = useInViewport(searchRef as any, { rootMargin: ROOT_MARGIN_VIEWPORT })
  const isMobile = useMediaQuery(IS_MOBILE_MEDIA_QUERY)
  const { ref, inView } = useInView({
    threshold: THRESHOLD_IN_VIEW,
    rootMargin: ROOT_MARGIN_IN_VIEW,
  })
  const { isGranted, showNotification } = useNotification()

  const [selectedNews, setSelectedNews] = useState<number | null>(null)
  const [tags, setTags] = useState<Set<string>>(new Set())
  const [isCreateAlertOpen, setCreateAlertOpen] = useState<boolean>(false)
  const [createAlertDefaultCurrencies, setCreateAlertDefaultCurrencies] = useState<string[]>([])

  const fetchCallback = useCallback(
    ({ pageParam = 0 }) => {
      if (tags.size > 0) {
        return fetchTagsNews(tags, pageParam)
      } else if (search) {
        return fetchSearchNews(search, pageParam)
      } else {
        return fetchAllNews(pageParam)
      }
    },
    [search, tags]
  )

  const {
    data: { pages: newsAll = [] } = { pages: [] },
    refetch,
    isRefetching,
  }: {
    data: any
    refetch: () => void
    fetchNextPage: () => void
    isRefetching: boolean
    isFetchingNextPage: boolean
  } = useInfiniteQuery(['news'], fetchCallback, {
    refetchInterval: REFRESH_TIME,
    refetchIntervalInBackground: true,
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPages, pages) => {
      if (NEWS_COUNT === lastPages?.length) {
        return pages.length
      }

      return undefined
    },
    onSuccess: (data) => {
      if (!isGranted) {
        updateAlertStamp(data?.pages?.flat(1)?.[0]?.[0] ?? 0)
      }
    },
  })

  const debouncedFetch = useMemo(
    () =>
      debounce(() => {
        refetch()
        window.scrollTo(0, 0)
      }, 500),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    []
  )

  const flattedNews = useMemo(() => newsAll?.flat(1) ?? [], [newsAll])
  const detailedItem = flattedNews.find((news: any) => news[0] === selectedNews) ?? null
  const detailedItemIndex = flattedNews.findIndex((news: any) => news[0] === selectedNews)
  const prevItem = flattedNews[detailedItemIndex - 1] ?? null
  const nextItem = flattedNews[detailedItemIndex + 1] ?? null
  const isDetailedViewOpen = Boolean(selectedNews) && !isMobile

  useEffect(() => {
    if (isGranted) {
      flattedNews.forEach((news: any, index: number, array: any) => {
        if (alertStamp < news[0]) {
          alertRules?.forEach((rule) => {
            if (rule.currencies.every((currency: string) => news[4].includes(currency))) {
              showNotification({
                title: news[3],
                body: news[7],
              })
            }
          })

          if (index === array.length - 1) {
            updateAlertStamp(news[0])
          }
        }
      })
    }
  }, [flattedNews, alertStamp, alertRules, showNotification, isGranted, updateAlertStamp])

  useLayoutEffect(() => {
    updateShowSearch(!searchInViewport)
  }, [searchInViewport, updateShowSearch])

  useEffect(() => {
    if (!detailedItem && selectedNews) {
      setSelectedNews(null)
    }
  }, [detailedItem, selectedNews])

  useEffect(() => {
    debouncedFetch()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, tags.size])

  useEffect(() => {
    if (inView) {
      //TODO uncomment when pagination will be ready
      // fetchNextPage()
    }
  }, [inView])

  const handleSearchChange = (text: string) => updateSearch(text)

  useEffect(() => {
    return () => {
      debouncedFetch.cancel()
    }
  })

  const handleTagsChange = (tag: string) => () => setTags((prevTags) => new Set(prevTags.add(tag)))
  const handleSelectNews = (id: number) => () => setSelectedNews(id)
  const handleOpenArticle = (link: string) => () => window.open(link, '_blank')
  const handleCopyUrl = (url: string) => () => {
    navigator.clipboard.writeText(url)
    toast('Copied to clipboard')
  }
  const handleCloseCreateAlert = () => {
    setCreateAlertOpen(false)
    setCreateAlertDefaultCurrencies([])
  }
  const handleOpenCreateAlert = (currencies: string[] = []) => {
    setCreateAlertOpen(true)
    setCreateAlertDefaultCurrencies(currencies)
  }

  return (
    <Box flexDirection="row">
      <Box
        flexDirection="column"
        alignItems="center"
        margin="0 auto"
        width="100%"
        backgroundColor="transparent"
        style={{
          maxWidth: '900px',
        }}
      >
        <Text color={theme.colors.white} fontSize="48px" lineHeight="57px">
          News
        </Text>
        <Text
          color={theme.colors.white}
          fontSize="18px"
          lineHeight="21px"
          margin="16px 16px 32px"
          style={{ textAlign: 'center' }}
        >
          Find out the latest crypto news
        </Text>
        <Search
          ref={searchRef}
          placeholder="Crypto news search"
          value={search}
          isLoading={isRefetching}
          onSearchChange={handleSearchChange}
        />
        {tags.size > 0 ? (
          <TagsContainer
            showSearchInHeader={!searchInViewport}
            isDetailedViewActive={Boolean(selectedNews) && !isMobile}
          >
            <>
              <Text color={theme.colors.white} margin="0 8px 0 0">
                Found {newsAll?.length ? newsAll.flat(1).length : 0} results for{' '}
              </Text>
              {Array.from(tags, (tag: string) => {
                const handleRemoveTag = () =>
                  setTags((prevTags) => new Set([...Array.from(prevTags).filter((t) => t !== tag)]))
                const CloseIcon: ElementType<any> = () => (
                  <X
                    color={theme.colors.white}
                    size={16}
                    onClick={handleRemoveTag}
                    style={{
                      cursor: 'pointer',
                    }}
                  />
                )

                return (
                  <Tag
                    margin="8px"
                    key={tag}
                    title={tag}
                    LeftIcon={CurrencyIcon}
                    textColor={theme.colors.white}
                    backgroundColor={theme.colors.quaternaryBackgroundAlt}
                    RightIcon={CloseIcon}
                  />
                )
              })}
            </>
          </TagsContainer>
        ) : null}
        {newsAll.length > 0 ? (
          <NewsContainer>
            {newsAll.map((group: any[], index: number) => (
              <Fragment key={`g${index}`}>
                {group.map((news: any, index) => {
                  return (
                    <InfoContainer key={`g${index}-n${news[0]}`} isActive={news[0] === selectedNews}>
                      <Box flexDirection="row" flexWrap="wrap">
                        {news[4].map((coin: string) => {
                          return (
                            <Tag
                              margin="0 8px 4px 0"
                              key={coin}
                              title={coin}
                              LeftIcon={CurrencyIcon}
                              textColor={theme.colors.white}
                              backgroundColor={tags.has(coin) ? theme.colors.quaternaryBackgroundAlt : undefined}
                              onClick={handleTagsChange(coin)}
                            />
                          )
                        })}
                      </Box>
                      <Box flexDirection="row" justifyContent="space-between" alignItems="center">
                        <Text
                          color={theme.colors.white}
                          margin="16px 16px 16px 0"
                          style={{ cursor: 'pointer' }}
                          onClick={handleSelectNews(news[0])}
                        >
                          {news[3]}
                        </Text>
                        {!isMobile && (
                          <Button buttonType={ButtonType.primary} onClick={toggleComingSoonModal}>
                            Trade
                          </Button>
                        )}
                      </Box>
                      <Box flexDirection="row" flexWrap="nowrap" alignItems="center">
                        <Box flexDirection="row" alignItems="center">
                          <Clock color={theme.colors.tertiary} size={14} />
                          <Text color={theme.colors.tertiary} fontSize="14px" margin="0 0 0 4px">
                            {formatDistanceDate(new Date(news[1] * 1000))}
                          </Text>
                        </Box>
                        {news[6] ? (
                          <LinkContainer href={removeFeedInUrl(news[6])} target="_blank">
                            <Link color={theme.colors.tertiary} size={14} />
                            <Text color={theme.colors.tertiary} fontSize="14px" margin="0 0 0 4px">
                              {hostnameUrl(news[6])}
                            </Text>
                          </LinkContainer>
                        ) : null}
                      </Box>
                      {isMobile && (
                        <>
                          {selectedNews === news[0] ? (
                            <Box flexDirection="row" alignItems="center" margin="0 0 16px 0">
                              <Text color={theme.colors.white}>{convertHtmlToText(news[7]) ?? ''}</Text>
                            </Box>
                          ) : null}
                          <Box flexDirection="row" margin="0 0 16px">
                            <Button buttonType={ButtonType.primary} margin="0 8px 0 0">
                              Trade
                            </Button>
                            {selectedNews === news[0] && news[6] ? (
                              <Button
                                buttonType={ButtonType.default}
                                color={theme.colors.white}
                                hoverBackgroundColor={theme.colors.white}
                                borderColor={theme.colors.white}
                                margin="0 16px 0 8px"
                                onClick={handleOpenArticle(removeFeedInUrl(news[6]))}
                              >
                                Open article
                              </Button>
                            ) : null}
                          </Box>
                          {selectedNews === news[0] ? (
                            <Box flexDirection="row" alignItems="center">
                              <Text color={theme.colors.white} fontSize="14px">
                                Share:{' '}
                              </Text>
                              <TwitterShareButton url={removeFeedInUrl(detailedItem[6])}>
                                <Twitter style={{ marginLeft: '8px', marginRight: '8px', cursor: 'pointer' }} />
                              </TwitterShareButton>
                              <TelegramShareButton url={removeFeedInUrl(detailedItem[6])}>
                                <Telegram style={{ marginRight: '8px', cursor: 'pointer' }} />
                              </TelegramShareButton>
                              <FacebookShareButton url={removeFeedInUrl(detailedItem[6])}>
                                <Facebook style={{ marginRight: '8px', cursor: 'pointer' }} />
                              </FacebookShareButton>
                              <LinkIcon
                                onClick={handleCopyUrl(removeFeedInUrl(detailedItem[6]))}
                                style={{ cursor: 'pointer' }}
                              />
                            </Box>
                          ) : null}
                          {selectedNews === news[0] ? <Trading createAlert={handleOpenCreateAlert} /> : null}
                        </>
                      )}
                    </InfoContainer>
                  )
                })}
                <span ref={ref} />
              </Fragment>
            ))}
          </NewsContainer>
        ) : null}
      </Box>
      <DetailedView
        isOpen={isDetailedViewOpen}
        detailedItem={detailedItem}
        prevItem={prevItem}
        nextItem={nextItem}
        tags={tags}
        setSelectedNews={setSelectedNews}
        tagsChange={handleTagsChange}
        openArticle={handleOpenArticle}
        copyUrl={handleCopyUrl}
        createAlert={handleOpenCreateAlert}
      />
      <Modal isOpen={isCreateAlertOpen} onClose={handleCloseCreateAlert}>
        {isCreateAlertOpen ? <CreateAlert defaultCurrencies={createAlertDefaultCurrencies} /> : null}
      </Modal>
    </Box>
  )
}

export default News
