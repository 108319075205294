import ReactDOM from 'react-dom'
import Modal, { TModal } from './Modal'

let modalRoot = document.getElementById('modal-root') as HTMLElement
if (!modalRoot) {
  modalRoot = document.createElement('div')
  modalRoot.setAttribute('id', 'modalRoot')
  document.body.appendChild(modalRoot)
}

const ModalPortal = (props: TModal) => ReactDOM.createPortal(<Modal {...props} />, modalRoot)

export default ModalPortal
