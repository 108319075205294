import { FC } from 'react'
import { useTheme } from 'styled-components'

import Box from 'components/Box'
import Text from 'components/Text'
import CurrencyIcon from 'components/CurrencyIcon'
import { ReactComponent as TradeUp } from '../../../../assets/svg/trade_up_arrow.svg'
import { ReactComponent as TradeDown } from '../../../../assets/svg/trade_down_arrow.svg'

import { Container } from './styles'

const Pair: FC<{
  currencyShortNameA: string
  currencyShortNameB: string
  rate: number
  percentage: number
  positiveTrend: boolean
}> = ({ currencyShortNameA, currencyShortNameB, rate, percentage, positiveTrend }) => {
  const theme = useTheme()

  return (
    <Container>
      <Box
        flexDirection="row"
        alignItems="center"
        flexWrap="wrap"
        justifyContent="center"
        width="100%"
        padding="18px 8px"
        gap="16px"
      >
        <CurrencyIcon currency={currencyShortNameA} width="32px" height="32px" useGrayFilter />
        <Box flexDirection="column" justifyContent="center" alignItems="center" height="auto">
          <Text fontSize="16px" fontWeight="600" color={theme.colors.white}>
            {currencyShortNameA}/{currencyShortNameB}
          </Text>
          <Box flexDirection="row" justifyContent="center" alignItems="center" gap="4px" height="auto">
            <Text fontSize="12px" color={theme.colors.tertiary}>
              {rate}
            </Text>
            <Text fontSize="12px" color={positiveTrend ? theme.colors.secondary : theme.colors.primary}>
              {percentage}%
            </Text>
            {positiveTrend ? <TradeUp /> : <TradeDown />}
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Pair
