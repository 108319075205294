import styled from 'styled-components'

export const SearchContainer = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border: 1px solid ${({ theme }) => theme.colors.quaternaryBackground};
  border-radius: 100px;
  padding: 18px 32px;

  width: -webkit-fill-available;
  margin: 0 24px;

  @media (min-width: 1160px) {
    width: 100%;
    max-width: 556px;
    margin: 0;
  }
`

export const SearchField = styled.input`
  width: 100%;
  background: transparent;
  font-size: 14px;
  height: 20px;
  border-width: 0;
  outline: none;
  margin-right: 60px;
  color: ${({ theme }) => theme.colors.white};

  ::placeholder {
    color: ${({ theme }) => theme.colors.quaternaryBackground};
    opacity: 1;
  }
`

export const IconContainer = styled.div`
  position: absolute;
  right: 21px;
  top: 17px;
  cursor: pointer;
`
