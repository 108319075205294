import styled, { css } from 'styled-components'

export const ModalOverlay = styled.div<{
  isOpen: boolean
}>`
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(5, 1, 20, 0.5);
  z-index: 101;

  ${({ isOpen }) =>
    isOpen
      ? css`
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 1s;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 500ms, opacity 500ms;
        `};
`

export const ModalContainer = styled.div<{
  isOpen: boolean
}>`
  display: flex;
  overflow-y: scroll;
  overflow-x: hidden;
  flex-direction: column;
  max-width: 344px;
  width: 100%;
  padding: 32px 24px;
  background: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 14px;
  z-index: 102;

  ${({ isOpen }) =>
    isOpen
      ? css`
          visibility: visible;
          opacity: 1;
          transition: visibility 0s linear 0s, opacity 1s;
        `
      : css`
          visibility: hidden;
          opacity: 0;
          transition: visibility 0s linear 500ms, opacity 500ms;
        `};
`
