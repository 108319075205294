import { createGlobalStyle } from 'styled-components'

import BGLines from './assets/svg/bg_lines.svg'

export default createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: Acronym, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;

    background-color: ${({ theme }) => theme.colors.primaryBackground};
    background-image: url(${BGLines});
    background-repeat: no-repeat;
    background-attachment: fixed;
    background-position: center center;
    background-size: cover;
  }

  * {
    box-sizing: border-box;
  }

  .Toastify__toast-theme--dark {
    background-color: ${({ theme }) => theme.colors.secondaryBackground};
  }
`
