import { addMinutes, format, formatDistanceToNow, isSameDay, isThisYear, subMinutes } from 'date-fns'

export const formatDistanceDate = (date: Date): string => {
  if (isSameDay(date, new Date())) {
    return formatDistanceToNow(date, { addSuffix: true })
  } else if (isThisYear(date)) {
    return format(date, 'd-MMM')
  } else {
    return format(date, 'd-MMM-yyy')
  }
}

export const formatTimezone = (date: Date): Date => {
  const offset = date.getTimezoneOffset()

  return Math.sign(offset) !== -1 ? addMinutes(date, offset) : subMinutes(date, Math.abs(offset))
}
