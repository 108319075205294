import { Routes, Route, BrowserRouter } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import News from 'pages/News'
import Header from 'components/Header'
import ScrollToTop from 'components/ScrollToTop'
import Modal from 'components/Modal/Modal'
import ComingSoon from 'components/ComingSoon'
import { useCommonStore } from 'store/common'

import GlobalStyles from './App.styles'

function App() {
  const { isComingSoonModalOpen, toggleComingSoonModal } = useCommonStore()

  return (
    <>
      <GlobalStyles />
      <BrowserRouter>
        <Header />
        <ScrollToTop>
          <Routes>
            <Route path="/" element={null} />
            <Route path="/news" element={<News />} />
            <Route path="/faq" element={null} />
            <Route path="/about" element={null} />
            <Route path="/features" element={null} />
            <Route path="/whitepaper" element={null} />
            <Route path="*" element={null} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
      <Modal isOpen={isComingSoonModalOpen} onClose={toggleComingSoonModal}>
        <ComingSoon />
      </Modal>
      <ToastContainer theme="dark" hideProgressBar newestOnTop />
    </>
  )
}

export default App
