import Box from 'components/Box'
import Button, { ButtonType } from 'components/Button'
import Text from 'components/Text'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import { Container } from './styles'

const Info: FC<{
  name: string
  shortName: string
  percentagePoints: number
  positiveTrend: boolean
  rate: number
  currencyShortNameA: string
  currencyShortNameB: string
  currencyRateA: number
  currencyRateB: number
  marketCup: number
  oneDayVolume: number
  toggleCreateAlert: () => void
}> = ({
  name,
  shortName,
  percentagePoints,
  positiveTrend,
  rate,
  marketCup,
  oneDayVolume,
  currencyShortNameA,
  currencyShortNameB,
  currencyRateA,
  currencyRateB,
  toggleCreateAlert,
}) => {
  const theme = useTheme()

  return (
    <Container>
      <Box flexDirection="row" justifyContent="space-between" gap="16px">
        <Box flexDirection="column" justifyContent="space-between">
          <Box flexDirection="row" alignItems="center" flexWrap="wrap" height="auto" width="auto" gap="8px">
            <Text fontSize="18px" fontWeight="600" color={theme.colors.white}>
              {name}
            </Text>
            <Text fontSize="18px" color={theme.colors.tertiary}>
              ${shortName}
            </Text>
          </Box>
          <Box height="auto">
            <Button
              buttonType={ButtonType.primary}
              color={theme.colors.white}
              padding="4px 8px"
              height="auto"
              onClick={toggleCreateAlert}
            >
              Create alert
            </Button>
          </Box>
        </Box>
        <Box flexDirection="column" justifyContent="flex-end" alignItems="center" height="auto" width="auto">
          <Box
            flexDirection="row"
            justifyContent="flex-end"
            alignItems="center"
            flexWrap="wrap"
            height="auto"
            width="auto"
            gap="8px"
          >
            <Text
              fontSize="14px"
              fontWeight="600"
              color={positiveTrend ? theme.colors.secondary : theme.colors.primary}
            >
              {percentagePoints}%
            </Text>
            <Text fontSize="18px" fontWeight="600" color={theme.colors.white}>
              ${rate}
            </Text>
          </Box>
          <Box flexDirection="column" alignItems="flex-end" width="100%" margin="4px 0 0 0">
            <Text fontSize="12px" color={theme.colors.white}>
              {currencyRateA} {currencyShortNameA}
            </Text>
            <Text fontSize="12px" color={theme.colors.white}>
              {currencyRateB} {currencyShortNameB}
            </Text>
            <Text fontSize="12px" color={theme.colors.white}>
              Market cup: ${marketCup}
            </Text>
            <Text fontSize="12px" color={theme.colors.white}>
              24h volume: ${oneDayVolume}
            </Text>
          </Box>
        </Box>
      </Box>
    </Container>
  )
}

export default Info
