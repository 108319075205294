import { ChangeEvent, FC } from 'react'

import { StyledInput } from './styles'

const Input: FC<{
  value: string
  placeholder: string
  disabled?: boolean
  onChange: (event: ChangeEvent<HTMLInputElement>) => void
}> = ({ value, disabled = false, placeholder, onChange }) => {
  return <StyledInput type="text" value={value} disabled={disabled} placeholder={placeholder} onChange={onChange} />
}

export default Input
