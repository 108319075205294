export const removeFeedInUrl = (url: string) => url.split(/\/feed|\/rss|\/~api/)[0]

export const hostnameUrl = (url: string) => new URL(url)?.hostname ?? ''

export const convertHtmlToText = (html: string = '') => {
  const parser = new DOMParser()
  const floatingElement = parser.parseFromString(`<html><body>${html}</body></html>`, 'text/xml')

  return floatingElement.documentElement.textContent
}
