import styled from 'styled-components'

export const Container = styled.div<{
  isMobile: boolean
}>`
  display: grid;
  grid-column: span 5;
  position: relative;
  align-items: center;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 12px;
  padding: ${({ isMobile }) => (isMobile ? '28px 8px 6px' : '28px 21px 6px')};

  > img {
    width: 100%;
    height: 100%;
  }
`

export const Title = styled.div`
  position: absolute;
  height: 18px;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 12px 12px 0px 0px;
  background-color: ${({ theme }) => theme.colors.quaternaryBackground}4D;
  font-size: 14px;
  line-height: 140%;
  color: ${({ theme }) => theme.colors.white};

  > svg {
    margin-right: 4px;
  }
`
