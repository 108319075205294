import styled from 'styled-components'

export default styled.div<{
  alignItems?: string
  backgroundColor?: string
  borderRadius?: string
  flexDirection?: string
  flexWrap?: string
  height?: string
  justifyContent?: string
  margin?: string
  padding?: string
  width?: string
  gap?: string
}>`
  align-items: ${({ alignItems }) => alignItems || 'flex-start'};
  background-color: ${({ backgroundColor }) => backgroundColor || 'transparent'};
  border-radius: ${({ borderRadius }) => borderRadius || 0};
  display: flex;
  position: relative;
  flex-direction: ${({ flexDirection }) => flexDirection || 'row'};
  flex-wrap: ${({ flexWrap }) => flexWrap || 'nowrap'};
  height: ${({ height }) => height || '100%'};
  justify-content: ${({ justifyContent }) => justifyContent || 'flex-start'};
  margin: ${({ margin }) => margin || 0};
  padding: ${({ padding }) => padding || 0};
  width: ${({ width }) => width || 'auto'};
  gap: ${({ gap }) => gap || 0};
`
