import Text from 'components/Text'
import { ElementType, FC, MouseEvent } from 'react'
import styled, { useTheme, DefaultTheme } from 'styled-components'

const Container = styled.div<{
  backgroundColor?: string
  margin?: string
}>`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 24px;
  padding: 4px 8px;
  border-radius: 100px;
  background-color: ${({ backgroundColor, theme }) => backgroundColor || theme.colors.quaternaryBackground};
  margin: ${({ margin }) => margin || 0};
`

const Tag: FC<{
  backgroundColor?: string
  LeftIcon?: ElementType | null | undefined
  onClick?: () => void
  onLeftIconClick?: () => void
  onRightIconClick?: () => void
  RightIcon?: ElementType | null | undefined
  title: string
  textColor: string
  margin?: string
}> = ({
  backgroundColor,
  LeftIcon,
  onClick,
  onLeftIconClick,
  onRightIconClick,
  RightIcon,
  title = '',
  textColor = '',
  margin = '',
}) => {
  const theme: DefaultTheme = useTheme()

  const handleClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onClick?.()
  }

  const handleLeftClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onLeftIconClick?.()
  }

  const handleRightClick = (e: MouseEvent<HTMLElement>) => {
    e.stopPropagation()
    onRightIconClick?.()
  }

  return (
    <Container margin={margin} backgroundColor={backgroundColor}>
      {LeftIcon ? (
        <LeftIcon
          useGrayFilter
          width="16px"
          height="16px"
          currency={title}
          onClick={handleLeftClick}
          style={{
            cursor: 'pointer',
          }}
        />
      ) : null}
      <Text
        color={textColor || theme.colors.white}
        fontSize={`${theme.fontSizes[2]}px`}
        lineHeight={`${theme.fontSizes[3]}px`}
        padding="4px"
        onClick={handleClick}
        style={{
          cursor: 'pointer',
        }}
      >
        {title}
      </Text>
      {RightIcon ? (
        <RightIcon
          width="16px"
          height="16px"
          onClick={handleRightClick}
          style={{
            cursor: 'pointer',
          }}
        />
      ) : null}
    </Container>
  )
}

export default Tag
