import styled from 'styled-components'

export const CurrenciesList = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 100%;
  max-height: 312px;
  min-height: 312px;
  height: 312px;
  height: 100%;
  overflow-y: auto;
  margin: 24px 0;
`

export const IconContainer = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
`
