import Box from 'components/Box'
import Text from 'components/Text'
import { FC } from 'react'
import { X } from 'react-feather'
import { useTheme } from 'styled-components'

const ComingSoon: FC<{
  onClose?: () => void
}> = ({ onClose = () => null }) => {
  const theme = useTheme()

  return (
    <Box flexDirection="column" height="auto">
      <Box flexDirection="row" flexWrap="nowrap" justifyContent="space-between" alignItems="center" width="100%">
        <Text fontSize="24px" lineHeight="26px" fontWeight="600" color={theme.colors.white}>
          Coming Soon!
        </Text>
        <X size={24} color={theme.colors.white} onClick={onClose} style={{ cursor: 'pointer' }} />
      </Box>
    </Box>
  )
}

export default ComingSoon
