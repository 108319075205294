import styled, { css } from 'styled-components'

export const DetailedContainer = styled.div<{ isOpen: boolean }>`
  display: flex;
  flex-direction: column;
  position: sticky;
  top: 112px;
  right: 0;
  bottom: 0;
  max-width: 50%;
  height: calc(100vh - 112px);
  background-color: ${({ theme }) => theme.colors.tertiaryBackground};
  padding: 40px 80px;
  overflow-y: auto;

  ${({ isOpen }) =>
    isOpen
      ? css`
          width: 100%;
          visibility: visible;
          transform: translateX(0);
          transition: visibility 0s linear 0s, transform 1s, width 0s;
        `
      : css`
          width: 0;
          padding: 0;
          visibility: hidden;
          transform: translateX(100%);
          transition: visibility 0s linear 500ms, transform 500ms, width 0s;
        `};
`

export const NewsTitle = styled.span<{ textAlign?: string }>`
  display: inline-block;
  width: 150px;
  height: 35px;
  overflow-y: auto;
  white-space: pre-line;
  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  margin-top: 4px;
  overflow: hidden;
  text-align: ${({ textAlign }) => textAlign || 'left'};
`
