export const REFRESH_TIME = 60000

export const NEWS_COUNT = 20

export const IS_MOBILE_MEDIA_QUERY = {
  maxWidth: 1160,
}

export const NOTIFICATION_TIMEOUT = 5000

export const ROOT_MARGIN_IN_VIEW = '500px'

export const ROOT_MARGIN_VIEWPORT = '-120px 0px 0px 0px'

export const THRESHOLD_IN_VIEW = 0

export const CURRENCY_PRIORITIES: { [key: string]: number } = {
  BTC: 50,
  ETH: 49,
  USDT: 48,
  USDC: 47,
  BNB: 46,
  XRP: 45,
  BUSD: 44,
  ADA: 43,
  SOL: 42,
  DOGE: 41,
  MATIC: 40,
  DOT: 39,
  DAI: 38,
  TRX: 37,
  SHIB: 36,
  UNI: 35,
  AVAX: 34,
  WBTC: 33,
  LEO: 32,
  LTC: 31,
  LINK: 30,
  ATOM: 29,
  ETC: 28,
  FTT: 27,
  XLM: 26,
  XMR: 25,
  CRO: 24,
  NEAR: 23,
  ALGO: 22,
  BCH: 21,
  QNT: 20,
  LUNC: 19,
  FLOW: 18,
  VET: 17,
  APE: 16,
  FIL: 15,
  TON: 14,
  ICO: 13,
  HBAR: 12,
  EGLD: 11,
  XTZ: 10,
  MANA: 9,
  SAND: 8,
  CHZ: 7,
  EOS: 6,
  AAVE: 5,
  THETA: 4,
  AXS: 3,
  USDP: 2,
  OKB: 1,
}

const common = {
  REFRESH_TIME,
  NEWS_COUNT,
  IS_MOBILE_MEDIA_QUERY,
  NOTIFICATION_TIMEOUT,
  ROOT_MARGIN_IN_VIEW,
  ROOT_MARGIN_VIEWPORT,
  THRESHOLD_IN_VIEW,
}

export default common
