import { Clock, X, Globe } from 'react-feather'
import { FC } from 'react'
import { TwitterShareButton, TelegramShareButton, FacebookShareButton } from 'react-share'
import { useTheme } from 'styled-components'

import { convertHtmlToText, hostnameUrl, removeFeedInUrl } from 'utils/common'
import { formatDistanceDate } from 'utils/formatDate'
import { LinkContainer } from 'pages/News/styles'
import { ReactComponent as Facebook } from 'assets/svg/facebook.svg'
import { ReactComponent as LeftArrow } from 'assets/svg/left-arrow.svg'
import { ReactComponent as LinkIcon } from 'assets/svg/link.svg'
import { ReactComponent as RightArrow } from 'assets/svg/right-arrow.svg'
import { ReactComponent as Telegram } from 'assets/svg/telegram.svg'
import { ReactComponent as Twitter } from 'assets/svg/twitter.svg'
import Box from 'components/Box'
import Button, { ButtonType } from 'components/Button'
import CurrencyIcon from 'components/CurrencyIcon'
import Tag from 'components/Tag'
import Text from 'components/Text'

import { DetailedContainer, NewsTitle } from './styles'
import Trading from 'components/Trading'
import { useCommonStore } from 'store/common'

const DetailedView: FC<{
  detailedItem: Array<any> | undefined
  prevItem: Array<any> | null
  nextItem: Array<any> | null
  tags: Set<string>
  isOpen: boolean
  setSelectedNews: (item: number | null) => void
  tagsChange: (coin: string) => () => void
  openArticle: (url: string) => () => void
  copyUrl: (url: string) => () => void
  createAlert: (currencies: string[]) => void
}> = ({
  detailedItem,
  prevItem,
  nextItem,
  tags,
  isOpen,
  setSelectedNews,
  tagsChange,
  openArticle,
  copyUrl,
  createAlert,
}) => {
  const theme = useTheme()
  const { toggleComingSoonModal } = useCommonStore()

  const handleRemoveSelection = () => setSelectedNews(null)
  const handleGoToPrevNews = () => setSelectedNews(prevItem?.[0])
  const handleGoToNextNews = () => setSelectedNews(nextItem?.[0])

  return (
    <DetailedContainer isOpen={isOpen}>
      {detailedItem ? (
        <>
          <Box flexDirection="row" justifyContent="flex-end" height="auto">
            <X color={theme.colors.white} size={20} onClick={handleRemoveSelection} style={{ cursor: 'pointer' }} />
          </Box>
          <Box flexDirection="row" flexWrap="wrap" height="auto" margin="32px 0 0 0">
            {detailedItem[4]?.map((coin: string) => {
              return (
                <Tag
                  margin="0 8px 8px 0"
                  key={coin}
                  title={coin}
                  LeftIcon={CurrencyIcon}
                  textColor={theme.colors.white}
                  backgroundColor={tags.has(coin) ? theme.colors.quaternaryBackgroundAlt : undefined}
                  onClick={tagsChange(coin)}
                />
              )
            })}
          </Box>
          <Text
            color={theme.colors.white}
            fontSize="24px"
            lineHeight="26px"
            fontWeight={theme.fontWeights.semiBold}
            margin="16px 0 0 0"
          >
            {detailedItem[3]}
          </Text>
          <Box flexDirection="row" flexWrap="nowrap" alignItems="center" margin="16px 0 0 0" height="auto">
            <Box flexDirection="row" alignItems="center">
              <Clock color={theme.colors.tertiary} size={14} />
              <Text color={theme.colors.tertiary} fontSize="14px" margin="0 0 0 4px">
                {formatDistanceDate(new Date(detailedItem[1] * 1000))}
              </Text>
            </Box>
            {detailedItem[6] ? (
              <LinkContainer href={removeFeedInUrl(detailedItem[6])} target="_blank">
                <Globe color={theme.colors.tertiary} size={14} />
                <Text color={theme.colors.tertiary} fontSize="14px" margin="0 0 0 4px">
                  {hostnameUrl(detailedItem[6])}
                </Text>
              </LinkContainer>
            ) : null}
          </Box>
          <Text color={theme.colors.white} margin="24px 0 0 0">
            {convertHtmlToText(detailedItem[7]) ?? ''}
          </Text>
          <Box flexDirection="row" justifyContent="space-between" height="auto" margin="32px 0 0">
            <Box flexDirection="row">
              <Button buttonType={ButtonType.primary} onClick={toggleComingSoonModal}>
                Trade
              </Button>
              {detailedItem[6] ? (
                <Button
                  buttonType={ButtonType.default}
                  color={theme.colors.white}
                  hoverBackgroundColor={theme.colors.white}
                  borderColor={theme.colors.white}
                  margin="0 16px 0 8px"
                  onClick={openArticle(removeFeedInUrl(detailedItem[6]))}
                >
                  Open article
                </Button>
              ) : null}
            </Box>
            <Box flexDirection="row" alignItems="center">
              <Text color={theme.colors.white} fontSize="14px">
                Share:{' '}
              </Text>
              <TwitterShareButton url={removeFeedInUrl(detailedItem[6])}>
                <Twitter style={{ marginLeft: '8px', marginRight: '8px', cursor: 'pointer' }} />
              </TwitterShareButton>
              <TelegramShareButton url={removeFeedInUrl(detailedItem[6])}>
                <Telegram style={{ marginRight: '8px', cursor: 'pointer' }} />
              </TelegramShareButton>
              <FacebookShareButton url={removeFeedInUrl(detailedItem[6])}>
                <Facebook style={{ marginRight: '8px', cursor: 'pointer' }} />
              </FacebookShareButton>
              <LinkIcon onClick={copyUrl(removeFeedInUrl(detailedItem[6]))} style={{ cursor: 'pointer' }} />
            </Box>
          </Box>
          <Trading createAlert={createAlert} />
          <Box flexDirection="row" height="auto" padding="32px 0 0" margin="auto -60px 0">
            {detailedItem && prevItem ? (
              <Box flexDirection="row" alignItems="center" style={{ cursor: 'pointer' }} onClick={handleGoToPrevNews}>
                <LeftArrow />
                <Box flexDirection="column" margin="0 0 0 16px">
                  <Text fontSize="14px" color={theme.colors.tertiary}>
                    Previous
                  </Text>
                  <NewsTitle>{prevItem[3]}</NewsTitle>
                </Box>
              </Box>
            ) : null}
            {detailedItem && nextItem ? (
              <Box
                flexDirection="row"
                alignItems="center"
                style={{ cursor: 'pointer' }}
                margin="0 0 0 auto"
                onClick={handleGoToNextNews}
              >
                <Box flexDirection="column" margin="0 16px 0 0" alignItems="flex-end">
                  <Text fontSize="14px" color={theme.colors.tertiary}>
                    Next
                  </Text>
                  <NewsTitle textAlign="right">{nextItem[3]}</NewsTitle>
                </Box>
                <RightArrow />
              </Box>
            ) : null}
          </Box>
        </>
      ) : null}
    </DetailedContainer>
  )
}

export default DetailedView
