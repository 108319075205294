import { FC } from 'react'

import { Container } from './styles'

const CurrencyIcon: FC<{
  currency: string
  width: string
  height: string
  useRoundedBackground?: boolean
  useGrayFilter?: boolean
}> = ({ currency, width, height, useRoundedBackground, useGrayFilter, ...restProps }) => {
  const icon = require(`../../assets/svg/crypto/${currency.toLowerCase()}.png`)

  return (
    <Container
      width={width}
      height={height}
      useRoundedBackground={useRoundedBackground}
      useGrayFilter={useGrayFilter}
      {...restProps}
    >
      {icon ? <img src={icon} width={width} height={height} alt={currency} /> : null}
    </Container>
  )
}

export default CurrencyIcon
