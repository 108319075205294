import { difference } from 'lodash'
import create from 'zustand'

export type Rule = {
  ruleId: string
  currencies: string[]
  importance: number | number[]
}

export const useNewsStore = create<{
  search: string
  showHeaderSidebar: boolean
  showSearchInHeader: boolean
  alertStamp: number
  alertRules: Rule[]
  updateSearch: (text: string) => void
  updateShowSearch: (flag: boolean) => void
  updateShowSidebar: (flag: boolean) => void
  updateAlertStamp: (stamp: number) => void
  addAlertRule: (rule: Omit<Rule, 'ruleId'>) => void
  removeAlertRule: (id: string) => void
  clearAlertRules: () => void
}>((set) => ({
  search: '',
  showSearchInHeader: false,
  showHeaderSidebar: false,
  alertStamp: 0,
  alertRules: [],
  updateSearch: (text) => set({ search: text }),
  updateShowSearch: (flag) => set({ showSearchInHeader: flag }),
  updateShowSidebar: (flag) => set({ showHeaderSidebar: flag }),
  updateAlertStamp: (stamp) => set({ alertStamp: stamp }),
  addAlertRule: (rule) =>
    set((state) => {
      const shouldAddRule = !state.alertRules.some(
        ({ currencies, importance }) =>
          difference(currencies, rule.currencies).length === 0 && importance === rule.importance
      )

      if (shouldAddRule) {
        return {
          alertRules: [
            ...state.alertRules,
            {
              ...rule,
              ruleId: crypto.randomUUID(),
            },
          ],
        }
      }

      return { ...state }
    }),
  removeAlertRule: (id) => set((state) => ({ alertRules: state.alertRules.filter(({ ruleId }) => ruleId !== id) })),
  clearAlertRules: () => set({ alertRules: [] }),
}))
