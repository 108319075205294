import React, { FC } from 'react'
import { SwitchInput, SwitchLabel, SwitchSpan } from './styles'

const Switch: FC<{
  checked: boolean
  onChange: (event: React.ChangeEvent<HTMLInputElement>) => void
}> = ({ checked = false, onChange = () => null }) => (
  <SwitchLabel>
    <SwitchInput type="checkbox" checked={checked} onChange={onChange} />
    <SwitchSpan />
  </SwitchLabel>
)

export default Switch
