import styled from 'styled-components'

export const Container = styled.div`
  display: grid;
  align-items: center;
  grid-column: span 5;
  position: relative;
  background-color: ${({ theme }) => theme.colors.secondaryBackground};
  border-radius: 12px;
`
