import React, { useEffect, FC } from 'react'
import { ModalContainer, ModalOverlay } from './styles'

export type TModal = {
  isOpen: boolean
  onClose: () => void
  children: JSX.Element | JSX.Element[] | null
}

const Modal: FC<TModal> = ({ isOpen, onClose, children }) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflowY = 'hidden'
    } else {
      document.body.removeAttribute('style')
    }

    if (!isOpen || !onClose) {
      return undefined
    }

    const listener: any = window.addEventListener('keyup', (event) => {
      if (event.key === 'Escape') {
        onClose()
      }
    })

    return () => {
      window.removeEventListener('keyup', listener)
    }
  }, [isOpen, onClose])

  const handleContainerClick = (event: React.MouseEvent) => event.stopPropagation()

  return (
    <ModalOverlay isOpen={isOpen}>
      <ModalContainer isOpen={isOpen} onClick={handleContainerClick}>
        {children
          ? React.Children.map(children, (child: React.ReactElement) => React.cloneElement(child, { onClose }))
          : null}
      </ModalContainer>
    </ModalOverlay>
  )
}

export default Modal
