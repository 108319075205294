import styled, { css } from 'styled-components'

export const Container = styled.div<{
  width?: string
  height?: string
  useRoundedBackground?: boolean
  useGrayFilter?: boolean
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 2px;
  width: ${({ width }) => width || 'auto'};
  height: ${({ height }) => height || 'auto'};

  > img {
    border-radius: 100px;
  }

  ${({ useRoundedBackground }) =>
    useRoundedBackground
      ? css`
          background-color: ${({ theme }) => theme.colors.tertiary};
        `
      : ''}

  ${({ useGrayFilter }) =>
    useGrayFilter
      ? css`
          filter: sepia(1) grayscale(1);
        `
      : ''}
`
