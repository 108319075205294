import styled from 'styled-components'

export const StyledInput = styled.input`
  width: 100%;
  font-size: 14px;
  height: 20px;
  border-width: 0;
  outline: none;
  background: ${({ theme }) => theme.colors.quaternaryBackground};
  color: ${({ theme }) => theme.colors.white};
  border-radius: 100px;
  padding: 18px 32px;

  ::placeholder {
    color: ${({ theme }) => theme.colors.tertiary};
    opacity: 1;
  }
`
