import Box from 'components/Box'
import Button, { ButtonType } from 'components/Button'
import Text from 'components/Text'
import { FC } from 'react'
import { useTheme } from 'styled-components'

import { Container } from './styles'

const SmallInfo: FC<{
  name: string
  shortName: string
  percentagePoints: number
  positiveTrend: boolean
  rate: number
  toggleCreateAlert: () => void
}> = ({ name, shortName, percentagePoints, positiveTrend, rate, toggleCreateAlert }) => {
  const theme = useTheme()

  return (
    <Container>
      <Box flexDirection="row" justifyContent="space-between" alignItems="center" gap="16px">
        <Box flexDirection="row" alignItems="center" flexWrap="wrap" height="auto" width="auto" gap="8px">
          <Text fontSize="18px" fontWeight="600" color={theme.colors.white}>
            {name}
          </Text>
          <Text fontSize="18px" color={theme.colors.tertiary}>
            ${shortName}
          </Text>
        </Box>
        <Box
          flexDirection="row"
          justifyContent="flex-end"
          alignItems="center"
          flexWrap="wrap"
          height="auto"
          width="auto"
          gap="8px"
        >
          <Text fontSize="14px" fontWeight="600" color={positiveTrend ? theme.colors.secondary : theme.colors.primary}>
            {percentagePoints}%
          </Text>
          <Text fontSize="18px" fontWeight="600" color={theme.colors.white}>
            ${rate}
          </Text>
        </Box>
      </Box>
      <Box margin="16px 0 0 0">
        <Button
          buttonType={ButtonType.primary}
          color={theme.colors.white}
          padding="4px 8px"
          height="auto"
          onClick={toggleCreateAlert}
        >
          Create alert
        </Button>
      </Box>
    </Container>
  )
}

export default SmallInfo
