import { FC } from 'react'

import { useMediaQuery } from 'react-responsive'

import { ReactComponent as TradeUp } from 'assets/svg/trade_up.svg'
import { ReactComponent as TradeDown } from 'assets/svg/trade_down.svg'
import { IS_MOBILE_MEDIA_QUERY } from 'constants/common'

import { Container, Title } from './styles'

const Graf: FC<{
  range: string
  positiveTrend: boolean
  url: string
}> = ({ range, url, positiveTrend }) => {
  const isMobile = useMediaQuery(IS_MOBILE_MEDIA_QUERY)

  return (
    <Container isMobile={isMobile}>
      <Title>
        {positiveTrend ? <TradeUp /> : <TradeDown />} {range}
      </Title>
      <img src={url} alt="graf" />
    </Container>
  )
}

export default Graf
